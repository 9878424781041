import React from "react"
import SocialIcons from "./SocialIcons"

const PageHeader = ({ title }) => {
    return(
        <div className="page-header">
            <div className="title-icons">
                <h1>{title}</h1>
                <SocialIcons />
            </div>
            <hr />
        </div>
    )
}

export default PageHeader;