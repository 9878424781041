import React from 'react'
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Card = ({ image, Title, Description, link }) => {
    const gatsbyImage = getImage(image)
    return (
        <div className="card-component">
            <Link to={link}>
                <GatsbyImage image={gatsbyImage} className="gatsby-image" alt={Title} />
            </Link>
            <h3>{Title}</h3>
            <p>{Description}</p>
        </div>
    )

}

export default Card;