import React from "react"
import HomeSwiper from './home-page/HomeSwiper'
import PageHeader from "./PageHeader"
import ReactMarkdown from "react-markdown"
import Card from './Card'
import BodyLayout from "../layouts/BodyLayout"

const Page = ({ data }) => {
    const find = '<img src="';
    const re = new RegExp(find, 'g');
    const doc = new RegExp('<a href="\/', 'g')

    return (
        <div>
            {data.Slider && data.Slider.length > 0 && 
                <HomeSwiper sources={data} />
            }
            <BodyLayout>
                <PageHeader title={data.Title} />
                <div>
                    <ReactMarkdown
                        source={data.Description.replace(re, `<img src="${process.env.API_URL}`).replace(doc, `<a href="${process.env.API_URL}/`)}
                        escapeHtml={false}
                        className="ck-content"
                    />
                </div>
                <br /><br /><br />
                <div className="page-subnavigator">
                    {data.Card && data.Card.map((e) => {
                        return (
                            <Card image={e.Image.localFile} Title={e.Title} Description={e.Description} link={e.Url} />
                        )
                    })}
                </div>
            </BodyLayout>
        </div>
    )
}

export default Page
