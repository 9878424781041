import React, { useEffect } from "react"
import Facebook from '../assets/images/social-icons/facebook.svg'
import Twitter from '../assets/images/social-icons/twitter.svg'
import Link from '../assets/images/social-icons/link.svg'
import {
    FacebookShareButton,
    TwitterShareButton,
    EmailShareButton,
} from 'react-share'

const SocialIcons = ({ }) => {
    const [link, setLink] = React.useState(false)
    useEffect(() => {
        const l = window.location.href
        setLink(l)
    }, [link])
    return (
        <div className="social-icons">
            <p>SHARE:</p>
            <FacebookShareButton url={link}>
                <div className="btn"><img src={Facebook} /></div>
            </FacebookShareButton>
            <TwitterShareButton url={link}>
                <div className="btn"><img src={Twitter} /></div>
            </TwitterShareButton>
            <EmailShareButton url={link}>
                <div className="btn"><img src={Link} /></div>
            </EmailShareButton>
        </div>
    )
}

export default SocialIcons;